import { Wallet } from '@injectivelabs/wallet-ts'
import { MainPage, WalletTableColumn, I18nMessageFunction } from '@/types'

export default {
  wallet: {
    table: {
      [WalletTableColumn.Asset]: 'Asset',
      [WalletTableColumn.Price]: 'Price',
      [WalletTableColumn.Balance]: 'Balance',
      [WalletTableColumn.TotalValueUsd]: 'Total value (USD)'
    },

    title: 'Wallet Overview',

    categories: {
      nfts: 'NFTs',
      [MainPage.Wallet]: 'Tokens',
      [MainPage.WalletCW20]: 'CW20 Tokens',
      [MainPage.WalletTransactions]: 'Transaction History'
    },

    notConnected: {
      title: 'No Wallet Connected',
      description: 'Get started by connecting your wallet',
      connectWallet: 'Connect Wallet',
      cta: 'New to web3? Learn how to setup a wallet'
    },

    tokens: {
      asset: 'Asset',
      balance: 'Balance',
      totalValueUsd: 'Total value (USD)'
    },

    balance: {
      ibcAssetPathDisplay:
        'The IBC assets have an unique way to display the origin and transfer paths of the assets.',
      pathToOrigin: 'Path to origin',
      unknownNetwork: 'Unknown network',
      whatDoesItMean: 'What does it mean?'
    },

    // wallet modal
    send: 'Send',
    beta: 'Beta',
    txFee: 'Tx Fee',
    wallet: 'Wallet',
    bridge: 'Bridge',
    convert: 'Convert',
    address: 'Address',
    sendInj: 'Send INJ',
    overview: 'Overview',
    checkAddress:
      'Please double check this address is correct. It is impossible to retrieve tokens from a wrong address.',
    convertToBank: 'Convert to Bank',
    convertToCw20: 'Convert to CW20',
    convertAmount: 'Convert Amount',
    enterInjectiveAddress: 'Enter Injective Address',
    enterMemo: 'Enter memo (required for most centralized exchange)',
    convertTokensMessage:
      'Convert the tokens into the bank module, and they will be available in your Injective wallet.',
    convertToCw20Message:
      'Convert the tokens back into CW20. Most applications on Injective use the Bank format instead of CW20 format.',
    myAccount: 'My Account',
    selectAddressToConnect: 'Select address to connect',
    'Please follow the instructions on your device':
      'Please follow the instructions on your device',
    getAddresses: 'Get addresses',
    getMoreAddresses: 'Get more addresses',
    gettingAddressesNote: 'We are getting your addresses, please wait ...',
    selectAddress: 'Select Address',
    selectLedgerAddress: 'Select Ledger Address',
    download: 'Download',
    connect: 'Connect',
    connected: 'Connected',
    connectedWallets: 'Connected Wallets',
    disconnect: 'Disconnect',
    connectToWallet: 'Connect to Wallet',
    addressCopied: 'Address copied to your clipboard',
    getFreeInj: 'Get free INJ for gas',
    torus: 'Torus',
    injNameDoesNotExist: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        named('injName'),
        ' does not exist. You can claim it at ',
        named('link')
      ]),
    connectUsingLedgerNote:
      'Note: To ensure smooth process while connecting your Ledger Hardware Wallet, please ensure you are running the latest Chrome version, have your Ledger device connected, unlocked and your Ethereum app open. ',
    connectUsingTrezorNote:
      'Note: To ensure smooth process while connecting your Trezor Hardware Wallet, please ensure you are running the latest Chrome version, have your Trezor device connected and unlocked.',
    trezorConnectNote:
      "Note: At this point there is no support for Trezor - please don't use Trezor (including Metamask's Trezor integration) as it might cause your funds being stuck on Injective.",

    memo: 'Memo',
    memoTooltip:
      'If the deposit platform requires you to fill in MEMO, please fill it in correctly. Missing or wrong filling of MEMO may cause loss of your assets.',
    memoPlaceholder: 'Your memo',

    convertToCw20TermsAndConditions:
      'I understand by converting back to CW20 I will not be able to use it to trade on Injective.',

    primaryWallet: ({ named }: I18nMessageFunction) =>
      `Primary ${named('wallet')}`,
    active: 'Active',
    ethereumAddress: 'Ethereum Address',
    transferToBankCompleted: 'Transfer to bank completed',
    transferToCw20Completed: 'Transfer to CW20 completed',

    bank: 'Bank',
    cw20: 'CW20',
    recognizeThisAsset: 'Recognize this Asset? Add details ',
    stakingMoved: "Looking for your Staking Information? We've moved it to the",
    stakingPage: 'Staking Page',
    trustWallet: 'Trust Wallet',
    transfer: 'Transfer',
    bridgeToInjective: 'Bridge to Injective',

    assetNotCanonical: ({ named }: I18nMessageFunction) =>
      `Consider bridging your asset back as it's not canonical and has no use case on Injective. Make sure to use the new bridge and the ${named(
        'canonical'
      )} network to bridge it back. Note: If you used Wormhole through the PortalBridge app, use it again to bridge the asset out.`,

    popular: 'Popular',
    otherWallets: 'Other wallets',
    successfullyConnected: 'Successfully Connected',
    successfullyDisconnected: 'Successfully Disconnected',
    disconnectWalletIssue: 'There was an issue disconnecting your wallet',

    connectUsingTorus: 'One-click login with',
    connectUsingBrowser: 'Connect using a browser wallet',
    connectUsingHardware: 'Connect using a hardware wallet',

    erc20: 'ERC 20',
    erc20InjTooltip:
      'Native INJ tokens are required for Injective dApps. Use the bridge to convert your ERC20 INJ tokens to the native version',
    migrate: 'Migrate',

    legacy: {
      actionRequired: 'Action Required',
      migrate: 'Migrate'
    },

    hardwareWallet: {
      getAddresses: 'Get addresses',
      getMoreAddresses: 'Get more addresses',
      selectAddressToConnect: 'Select address to connect',
      getAddressNote: 'We are getting your addresses, please wait ...'
    },

    trezor: {
      note: 'Note: To ensure smooth process while connecting your Trezor Hardware Wallet, please ensure you are running the on latest Chrome version, have your Trezor device connected and unlocked.'
    },

    legerCosmos: {
      note: 'Note: To ensure smooth process while connecting your Ledger Hardware Wallet, please ensure you are running the latest Chrome version, have your Ledger device connected, unlocked and your Cosmos app open. '
    },

    ledger: {
      ledgerLive: 'Ledger Live',
      ledgerLegacy: 'Ledger Legacy',
      derivationPath: 'Derivation Path',
      selectDerivationPath: 'Select Derivation Path',
      note: 'Note: To ensure smooth process while connecting your Ledger Hardware Wallet, please ensure you are running the on latest Chrome version, have your Ledger device connected, unlocked and your Ethereum app open.'
    },

    option: {
      [Wallet.Leap]: 'Leap',
      [Wallet.Keplr]: 'Keplr',
      [Wallet.Torus]: 'Torus',
      [Wallet.Ninji]: 'Ninji',
      [Wallet.Ledger]: 'Ledger',
      [Wallet.Trezor]: 'Trezor',
      [Wallet.Phantom]: 'Phantom',
      [Wallet.Metamask]: 'Metamask',
      [Wallet.OkxWallet]: 'OKX Wallet',
      [Wallet.BitGet]: 'Bitget Wallet',
      [Wallet.TrustWallet]: 'Trust Wallet',
      [Wallet.Cosmostation]: 'Cosmostation',
      [Wallet.WalletConnect]: 'Wallet Connect'
    }
  }
}
