import { default as _91_91auction_93_93IPUzC1fxzvMeta } from "/root/injective/injective-hub/pages/auction/[[auction]].vue?macro=true";
import { default as bridgewpId7MC5xUMeta } from "/root/injective/injective-hub/pages/bridge.vue?macro=true";
import { default as envOfEtSNKL28Meta } from "/root/injective/injective-hub/pages/env.vue?macro=true";
import { default as external_45linkInROUAF9UwMeta } from "/root/injective/injective-hub/pages/external-link.vue?macro=true";
import { default as faqTbFNpzhTY1Meta } from "/root/injective/injective-hub/pages/faq.vue?macro=true";
import { default as featured_45dappsJxdjmd3kx1Meta } from "/root/injective/injective-hub/pages/featured-dapps.vue?macro=true";
import { default as governanceXqKlf8djrEMeta } from "/root/injective/injective-hub/pages/governance.vue?macro=true";
import { default as indexlRw92BBa4sMeta } from "/root/injective/injective-hub/pages/index.vue?macro=true";
import { default as maintenanceZQUcX7VQw6Meta } from "/root/injective/injective-hub/pages/maintenance.vue?macro=true";
import { default as _91proposal_93KK2AvfEfWvMeta } from "/root/injective/injective-hub/pages/proposal/[proposal].vue?macro=true";
import { default as _91_91type_93_93dHkG9DDtiWMeta } from "/root/injective/injective-hub/pages/proposal/create/[[type]].vue?macro=true";
import { default as index9BsYd2vh3gMeta } from "/root/injective/injective-hub/pages/stake/index.vue?macro=true";
import { default as my_45stakingDQ9qh6tgp6Meta } from "/root/injective/injective-hub/pages/stake/my-staking.vue?macro=true";
import { default as stakegxPWf5WVGyMeta } from "/root/injective/injective-hub/pages/stake.vue?macro=true";
import { default as stakingKMC4fZ9XzIMeta } from "/root/injective/injective-hub/pages/staking.vue?macro=true";
import { default as token_45factoryfLpHP8SviNMeta } from "/root/injective/injective-hub/pages/token-factory.vue?macro=true";
import { default as _91validator_93mUlWoVSLE4Meta } from "/root/injective/injective-hub/pages/validators/[validator].vue?macro=true";
import { default as cw20_45tokensS1vC5Vvg9mMeta } from "/root/injective/injective-hub/pages/wallet/cw20-tokens.vue?macro=true";
import { default as index90Kf6a2CHfMeta } from "/root/injective/injective-hub/pages/wallet/index.vue?macro=true";
import { default as transactionsrToXg6yWwHMeta } from "/root/injective/injective-hub/pages/wallet/transactions.vue?macro=true";
import { default as walletXfEBaSdxjZMeta } from "/root/injective/injective-hub/pages/wallet.vue?macro=true";
import { default as whiteboardJtZbMukMt8Meta } from "/root/injective/injective-hub/pages/whiteboard.vue?macro=true";
export default [
  {
    name: "auction-auction",
    path: "/auction/:auction?",
    component: () => import("/root/injective/injective-hub/pages/auction/[[auction]].vue")
  },
  {
    name: "bridge",
    path: "/bridge",
    meta: bridgewpId7MC5xUMeta || {},
    component: () => import("/root/injective/injective-hub/pages/bridge.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envOfEtSNKL28Meta || {},
    component: () => import("/root/injective/injective-hub/pages/env.vue")
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linkInROUAF9UwMeta || {},
    component: () => import("/root/injective/injective-hub/pages/external-link.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/root/injective/injective-hub/pages/faq.vue")
  },
  {
    name: "featured-dapps",
    path: "/featured-dapps",
    component: () => import("/root/injective/injective-hub/pages/featured-dapps.vue")
  },
  {
    name: "governance",
    path: "/governance",
    component: () => import("/root/injective/injective-hub/pages/governance.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/injective/injective-hub/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceZQUcX7VQw6Meta || {},
    component: () => import("/root/injective/injective-hub/pages/maintenance.vue")
  },
  {
    name: "proposal-proposal",
    path: "/proposal/:proposal()",
    meta: _91proposal_93KK2AvfEfWvMeta || {},
    component: () => import("/root/injective/injective-hub/pages/proposal/[proposal].vue")
  },
  {
    name: "proposal-create-type",
    path: "/proposal/create/:type?",
    component: () => import("/root/injective/injective-hub/pages/proposal/create/[[type]].vue")
  },
  {
    name: stakegxPWf5WVGyMeta?.name,
    path: "/stake",
    component: () => import("/root/injective/injective-hub/pages/stake.vue"),
    children: [
  {
    name: "stake",
    path: "",
    component: () => import("/root/injective/injective-hub/pages/stake/index.vue")
  },
  {
    name: "stake-my-staking",
    path: "my-staking",
    component: () => import("/root/injective/injective-hub/pages/stake/my-staking.vue")
  }
]
  },
  {
    name: "staking",
    path: "/staking",
    component: () => import("/root/injective/injective-hub/pages/staking.vue")
  },
  {
    name: "token-factory",
    path: "/token-factory",
    component: () => import("/root/injective/injective-hub/pages/token-factory.vue")
  },
  {
    name: "validators-validator",
    path: "/validators/:validator()",
    component: () => import("/root/injective/injective-hub/pages/validators/[validator].vue")
  },
  {
    name: walletXfEBaSdxjZMeta?.name,
    path: "/wallet",
    component: () => import("/root/injective/injective-hub/pages/wallet.vue"),
    children: [
  {
    name: "wallet-cw20-tokens",
    path: "cw20-tokens",
    component: () => import("/root/injective/injective-hub/pages/wallet/cw20-tokens.vue")
  },
  {
    name: "wallet",
    path: "",
    component: () => import("/root/injective/injective-hub/pages/wallet/index.vue")
  },
  {
    name: "wallet-transactions",
    path: "transactions",
    component: () => import("/root/injective/injective-hub/pages/wallet/transactions.vue")
  }
]
  },
  {
    name: "whiteboard",
    path: "/whiteboard",
    component: () => import("/root/injective/injective-hub/pages/whiteboard.vue")
  },
  {
    name: "proposals-proposal",
    path: "/proposals/:proposal?",
    component: () => import("/root/injective/injective-hub/pages/governance.vue")
  },
  {
    name: "proposals",
    path: "/proposals",
    component: () => import("/root/injective/injective-hub/pages/governance.vue")
  }
]