import { defineStore } from 'pinia'
import { injToken } from '@shared/data/token'
import { exchangeApi, tokenCacheApi, tokenPriceService } from '@shared/Service'
import { Coin, TokenStatic, TokenVerification } from '@injectivelabs/sdk-ts'
import { tokens } from '@/app/json'
import { getToken } from '@/app/utils/helpers'
import { tokenFactoryStatic } from '@/app/Service'

type TokenStoreState = {
  tokenUsdPriceMap: Record<string, number>
  injSupply: Coin
  chainDenomDecimalsMap: Record<string, number>
  chainDenomMinNotionalMap: Record<string, number>
  unknownTokens: TokenStatic[]
}

const initialStateFactory = (): TokenStoreState => ({
  injSupply: {} as Coin,
  unknownTokens: [],
  tokenUsdPriceMap: {},
  chainDenomDecimalsMap: {},
  chainDenomMinNotionalMap: {}
})

export const useTokenStore = defineStore('token', {
  state: (): TokenStoreState => initialStateFactory(),
  getters: {
    tokens: () =>
      tokens.filter(
        (token: TokenStatic) =>
          token.tokenVerification === TokenVerification.Verified
      ),

    minNotionalTokens: (state) =>
      Object.keys(state.chainDenomMinNotionalMap)
        .map((denom) => tokenFactoryStatic.getMetaByDenomOrAddress(denom))
        .filter((token) => token) as TokenStatic[],

    tokenBySymbol: () => (symbol: string) => {
      return tokenFactoryStatic.getMetaBySymbol(symbol, {
        verification: TokenVerification.Verified
      })
    },

    tokenByDenomOrSymbol:
      (state) =>
      (denomOrSymbol: string): TokenStatic | undefined => {
        if (!denomOrSymbol) {
          return
        }

        return (
          tokenFactoryStatic.toToken(denomOrSymbol) ||
          state.unknownTokens.find(
            (token) => token.denom.toLowerCase() === denomOrSymbol.toLowerCase()
          )
        )
      },

    tokenUsdPrice: (state) => (coinGeckoId: string) => {
      return state.tokenUsdPriceMap[coinGeckoId] || 0
    }
  },
  actions: {
    /**
     * Used to fetch unknown token metadata
     * from external/internal API sources
     * for particular set of denoms (account page/single asset page)
     **/
    async fetchUnknownTokensList() {
      const tokenStore = useTokenStore()

      if (tokenStore.unknownTokens.length > 0) {
        return
      }

      const { supply } = await tokenCacheApi.fetchTotalSupply()

      const injSupply = supply.find((coin) => coin.denom === injToken.denom)!

      const denomsWithoutTokens = supply
        .filter((coin) => !tokenStore.tokenByDenomOrSymbol(coin.denom))
        .map((coin) => coin.denom)

      const denomTokensToFetch = denomsWithoutTokens.filter(
        (denom) =>
          !tokenStore.unknownTokens.find((token) => token.denom === denom)
      )

      let unknownTokens: TokenStatic[] = []

      tokenStore.$patch({
        injSupply
      })

      for (const denom of denomTokensToFetch) {
        const token = await getToken(denom)

        if (!token) {
          continue
        }

        unknownTokens = [...unknownTokens, token]
      }

      tokenStore.$patch({
        unknownTokens: [...tokenStore.unknownTokens, ...unknownTokens]
      })
    },

    async getTokensUsdPriceMap(coinGeckoIdList: string[] = []) {
      const tokenStore = useTokenStore()

      tokenStore.tokenUsdPriceMap = await tokenPriceService.fetchUsdTokensPrice(
        coinGeckoIdList
      )
    },

    async fetchDenomDecimals() {
      const tokenStore = useTokenStore()

      const data = await exchangeApi.fetchDenomDecimals()

      tokenStore.$patch({
        chainDenomDecimalsMap: data.reduce(
          (list, item) => ({ ...list, [item.denom]: item.decimals }),
          {}
        )
      })
    },

    async fetchDenomMinNotionals() {
      const tokenStore = useTokenStore()
      const data = await exchangeApi.fetchDenomMinNotionals()

      tokenStore.$patch({
        chainDenomMinNotionalMap: data.reduce(
          (list, item) => ({ ...list, [item.denom]: item.minNotional }),
          {}
        )
      })
    },

    reset() {
      useTokenStore().$reset()
    }
  }
})
